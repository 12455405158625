<template>
  <div v-show="!loading" class="container pd100">
    <van-cell :title="detail.meeting_name" />
    <div class="cell-sub mb10">
      <!-- <div class="cell-sub-p">
        <div class="cell-sub-p-hd mr5">活动名称：</div>
        <div class="cell-sub-p-bd">{{ detail.meeting_name }}</div>
      </div> -->
      <div class="cell-sub-p">
        <div class="cell-sub-p-hd mr5">开始时间：</div>
        <div class="cell-sub-p-bd">{{ detail.meeting_start_date }}</div>
      </div>
      <div class="cell-sub-p">
        <div class="cell-sub-p-hd mr5">结束时间：</div>
        <div class="cell-sub-p-bd">{{ detail.meeting_end_date }}</div>
      </div>
    </div>

    <van-cell title="票务选择" />
    <div class="cell-sub mb10">
      <!-- direction="horizontal" -->
      <van-radio-group :value="typeId">
        <div v-for="(item,index) in ticketList" :key="item.type_id" class="ticket-item" @click="onChange(item)">
          <div class="ticket-item-price">￥{{ item.price }}</div>
          <div class="ticket-item-name">{{ item.type_name }}</div>
          <!-- {{ item.type_left_book }} -->
          <van-radio :name="item.type_id" />
        </div>
      </van-radio-group>
    </div>
    <van-cell v-show="can_repeat_purchase" title="报名人数" />
    <div v-show="can_repeat_purchase" class="cell-sub mb10 signup">
      <van-stepper v-model="count" class="mr10" :min="1" :max="countMax" />
      <div class="total">
        共：￥{{ totalPrice }}
      </div>
    </div>

    <van-cell title="报名人信息" />
    <div class="bgf mb10 info">
      <div class="form">
        <i-field
          v-for="(item,index) in form"
          :key="index"
          :index="index"
          :field="item"
          :type="item.filedValType"
          :name="item.filedDataName"
          :label="item.filedName"
          :placeholder="item.filedPlaceholder"
          :val="item.value"
          :vals="item.values"
          @change="onFieldChange"
        />
      </div>
    </div>
    <div class="affix-bar">
      <van-button
        class="affix-bar-btn"
        block
        type="primary"
        @click="onSubmit"
      >确认报名</van-button>
    </div>
    <copyright v-show='!loading' />
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import { Field, Stepper, RadioGroup, Radio, CouponCell, CouponList } from 'vant'
import { isEmpty, isPhone } from '@/common/validate'
import { wxPay } from '@/common/wx'
import IField from '@/components/Field'
import Copyright from '@/components/Copyright'

export default {
  name: 'EventsSubmit',
  components: {
    [Stepper.name]: Stepper,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Field.name]: Field,
    [CouponCell.name]: CouponCell,
    [CouponList.name]: CouponList,
    IField,
    Copyright
  },
  mixins: [PageMixin],
  data() {
    return {
      banner: [],
      detail: {},
      ticketList: [],
      typeId: '',
      price:'',
      countMax: 10,
      count: 1,
      username: '',
      phone: '',
      form: [],
      loading:false,
      can_repeat_purchase:0,
      meet_id:'',
      ticket_id:'',
      channel_id:'',
      showList:false,
      chosenCoupon: -1,
      coupons: [],
      disabledCoupons:[],
    }
  },
  computed: {
    totalPrice() {
      const price = (this.price * 100 * this.count)/100
      return (price).toFixed(2)
    }
  },
  created() {
    this.onDetail()
  },
  methods: {
    onDetail() {
      this.loading = true
      this.$toast.loading({
        mask: true,
        message: '加载中...',
        duration: 0
      })
      let meet = this.$route.query
      this.meet_id = meet.meeting_id
      this.ticket_id = meet.ticket_id
      this.channel_id = meet.channel_id
      let obj = { meeting_id: this.meet_id }
      if(this.ticket_id){
        obj.ticket_id = this.ticket_id
      }
      if(this.channel_id){
        obj.channel_id = this.channel_id
      }
      this.$api.events_book(obj).then(res => {
        this.detail = res.data
        this.banner = [{ id: '111', image: res.data.meeting_cover }]
        this.ticketList = res.data.meeting_ticket_type
        this.typeId = res.data.meeting_ticket_type ? res.data.meeting_ticket_type[0].type_id : ''
        this.can_repeat_purchase = res.data.meeting_ticket_type ? res.data.meeting_ticket_type[0].can_repeat_purchase : 0
        this.price = res.data.meeting_ticket_type ? res.data.meeting_ticket_type[0].price : ''
        if(res.data.ticket_count === '不限'){
          this.countMax = 1000000
        }else{
          this.countMax = res.data.ticket_count - res.data.already_count
        }
        const form = []
        res.data.meeting_form.forEach(item => {
          const required = item.filedRequired
          let value = ''
          let values = []
          if (item.filedValType === 'select') {
            value = item.filedDataSelect[0]
            values = item.filedDataSelect
          } else if (item.filedValType === 'radio') {
            value = item.filedDataRadio[0]
            values = item.filedDataRadio
          } else {
            value = ''
          }

          form.push({
            ...item,
            required,
            value,
            values
          })
        })
        this.form = form
        this.$toast.clear()
        this.loading = false
      })
    },
    onFieldChange({ index, value }) {
      this.form[index].value = value
    },
    formValidate(form) {
      for (let index = 0; index < form.length; index++) {
        if (form[index].required && isEmpty(form[index].value)) {
          console.log(form[index].filedName)
          this.$toast(`请输入${form[index].filedName}`)
          return false
        }
      }
      return true
    },
    onChange(item) {
      this.typeId = item.type_id
      this.price = item.price
      this.count = 1
      this.can_repeat_purchase = item.can_repeat_purchase
    },
    onSubmit() {
      if (this.formValidate(this.form)) {
        for(let i=0; i<this.form.length; i++){
          if(this.form[i].filedDataName == 'cellphone' && !isPhone(this.form[i].value)){
            this.$toast('请填写正确手机号~')
            return false
          }
        }
        const params = {
          event_id: this.$route.query.meeting_id,
          _source:this.$route.query._source,
          type_id: this.typeId,
          ticket_count: this.count,
          ticket_info: JSON.stringify(this.form.map(item => ({ [item.filedDataName]: item.value })))
        }
        if(this.ticket_id){
          params.ticket_id = this.ticket_id
        }
        if(this.channel_id){
          params.channel_id = this.channel_id
        }
        this.$toast.loading({
          mask: true,
          message: '报名中...',
          duration: 0
        })

        this.$api.payment_events(params)
          .then(res => {
            this.$toast.clear()
            // 免费
            if (!res.data.need_pay) {
              this.$dialog.alert({
                title: '活动报名成功',
                message: `活动单号：${res.data.out_trade_no}`,
                confirmButtonText: '去查看'
              })
                .then(() => {
                  // on close
                  // 去查看活动票据
                  this.$router.replace({
                    path: `/events/ticket?ticket_id=${res.data.ticket_id}`
                  })
                })
              return
            }else{
              localStorage.setItem('money',Number(this.totalPrice)*100)
              this.$router.push({path:'/orderCenter',query:{toPath:`/events/ticket?ticket_id=${res.data.ticket_id}`,out_trade_no:res.data.out_trade_no}})
            }
            // 收费
            // const { appId, timeStamp, nonceStr, package: packageStr, signType, paySign } = res.data.jsApiParameters
            // wxPay( appId, timeStamp, nonceStr, packageStr, signType, paySign)
            //   .then(result => {
            //     this.$dialog.alert({
            //       title: '活动报名成功',
            //       message: `活动单号：${res.data.out_trade_no}`,
            //       confirmButtonText: '去查看'
            //     })
            //       .then(() => {
            //         // on close
            //       // 去查看活动票据
            //         this.$router.replace({
            //           path: `/events/ticket?ticket_id=${res.data.ticket_id}`
            //         })
            //       })
            //   })
            //   .catch(err => {
            //     this.$dialog.alert({
            //       title: '支付失败',
            //       message: '活动支付失败',
            //       confirmButtonText: '重新尝试'
            //     })
            //       .then(() => {
            //       // on close
            //       })
            //     console.error(err)
            //   })
          })
          .catch(err => {
            this.$toast.fail('活动报名失败')
            console.error(err)
          })
      }
    },
    onCouponChange(index) {
      this.showList = false
      this.chosenCoupon = index
    },
    onExchange(code) {
      this.coupons.push(coupon)
    },
  }
}
</script>

<style lang="less" scoped>

  .cell-sub{
      background: #fff;
      padding: 10px 15px;
  }

  .cell-sub-p{
      display: flex;
      margin-bottom: 6px;
      &-hd{
          color: #333;
      }
      &-bd{
          color: #666;
      }
  }

  .ticket-item{
      display: flex;
      align-items: center;
      height: 44px;
      &-price{
          color: #00DE93;
          min-width: 80px;
      }
      &-name{
          flex: 1;
      }
  }
  .signup{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .total{
          color: #00DE93;
      }
  }
  .info{

  }
</style>

